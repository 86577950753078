.vault-table-container {
    .vault-table {
        width: 100%;
        background-color: #171717;
        margin-bottom: 3rem;
    }

    .vault-table > thead > tr {
        background: #171717 0% 0% no-repeat padding-box;
        border-radius: 5px;
        opacity: 1;
        font-size: 0.95rem;
    }

    .vault-table > thead > tr > th {
        text-transform: uppercase;
        color: lightgray;
        padding: 1rem 0.825rem;
    }

    .vault-table > tbody > tr {
        border: 2px solid #22242c;
        border-radius: 5px;
        opacity: 1;
        font-size: 0.99rem;
    }

    .vault-table > tbody > tr > td {
        text-transform: uppercase;
        color: lightgray;
        vertical-align: middle !important;
        padding: 0.87rem 0.825rem;
    }

    .vault-table > tbody > tr > td:first-child,
    .vault-table > thead > tr > th:first-child {
        padding-left: 1.5rem;
    }

    .vault-table > tbody > tr > td:last-child,
    .vault-table > thead > tr > th:last-child {
        padding-right: 1.5rem;
    }

    .btn-brand {
        color: #171717;
        font-weight: 500;
        background-color: #27debf;
        // padding: 1% 5%;
    }

    .button-cell {
        padding: 0rem 0.3rem;
    }

    .btn-brand-view {
        padding: 0.4rem 3rem !important;
    }

    /* // Extra small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up) */

    @media (min-width: 768px) and (max-width: 1024px) {
        .table-container {
            overflow-x: scroll;
        }

        .vault-table {
            width: 150%;
        }
    }

    @media (min-width: 481px) and (max-width: 767px) {
        .table-container {
            overflow-x: scroll;
        }

        .vault-table {
            width: 200%;
        }
    }

    @media (max-width: 480px) {
        .table-container {
            overflow-x: scroll;
        }

        .vault-table {
            width: 200%;
        }

        .vault-table > thead > tr {
            font-size: 0.75rem;
        }

        .vault-table > thead > tr > th {
            padding: 0.9rem 0.725rem;
        }

        .vault-table > tbody > tr {
            font-size: 0.8rem;
        }

        /* #root > div > div > div > main > div:nth-child(4) > div > div > table > tbody > tr:nth-child(1) > td.pl-5 */

        .vault-table > tbody > tr > td:first-child {
            padding-left: 1rem;
        }

        .vault-table > tbody > tr > td:last-child {
            padding-right: 1rem;
        }

        .vault-table > tbody > tr > td {
            padding: 0.87rem 0.725rem;
        }

        .btn-brand-view {
            padding: 0.4rem 2rem !important;
        }
    }
}
