.body-content {
  margin-top: -63px;
}

.brand-color {
  color: #27debf;
}

.brand-color-black {
}

.brand-color-white {
  color: lightgray;
}

.brand-color-shadow-black {
  color: #171717;
}

.brand-bgcolor-shadow-black {
  background-color: #171717;
}

.brand-color-grey {
  color: #c2c2c3;
}

.content-padding {
  padding: 0 9%;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.font-bold {
  font-weight: 600 !important;
}

@media (max-width: 576px) {
  .content-padding {
    padding: 0 4%;
  }
  .airdrop-header { 
    height: 4rem !important;
  }
}

.airdrop-header {
  /* background-color: #1e2027; */
  background-image: linear-gradient(35deg, #27debf, #192020, #192020, #27debf);
  /* background-image: linear-gradient(35deg, #27debf, lightgray, white, lightgray, #27debf); */
  color: lightgray;
  font-weight: 600;
  letter-spacing: 0.8px;
  /* border: solid 1px #27debf; */
  height: 3rem;
  display: flex;
  align-items: center;
}

.airdrop-header-content {
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  text-align: center;
}

.airdrop-header-content a {
  color: lightgray;
}

.airdrop-header-content a:hover {
  color: lightgray;
}

.airdrop-header-close {
  padding-right: 1.5rem;
  cursor: pointer;
}
