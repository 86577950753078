.vesting-table {
    width: 100%;

    tbody > {
        tr {
            border: 2px solid #404254;
            border-radius: 5px;
            opacity: 1;
            td {
                vertical-align: middle !important;
                padding: 0.5rem 0rem
            }
        }
    }
}