.btn-logout {
    color: lightgray;
    border: 1px white solid;
    padding: 1% 6%;
}

.btn-view-on {
    color: #171717;
    background-color: #27DEBF;
    padding: 1% 6%;
}

.btn-logout:hover {
    color: #27DEBF
}

.account-address {
    font-size: 15px;
}

.information-note {
    color: #9e9c9c
}
/* 
.btn-brand {
    color: lightgray;
    background-color: #27DEBF;
    padding: 1% 5%;
}

.btn-brand-border {
    color: lightgray;
    border: 1px white solid;
    padding: 1% 3%;
}

.btn-brand-border:hover {
    color: #27DEBF
} */