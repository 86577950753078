.create-vesting-page {
    .create-lock-form-content h2 {
        font-size: 1rem;
    }

    .create-lock-form-content h4 {
        font-size: 1.3rem;
    }

    .create-lock-form-content h5 {
        font-size: 1.2rem;
    }

    .create-lock-form-content h6 {
        font-size: 0.75rem;
    }

    .create-lock-form-content p {
        font-size: 0.9rem;
    }

    .left-section {
        padding: 5.5rem 1rem 3.5rem 3.5rem;
        background-color: #1e2027;
        border-radius: 10px;
        opacity: 1;
    }

    .right-section {
        margin: 2rem auto 2rem -3.3rem;
        padding: 1rem 1rem 1rem 3.3rem;
        background-color: #191919;
        border: 5px solid #1e2027;
        border-radius: 10px;
    }

    .number-normal {
        padding: 0px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        border-radius: 50%;
        border: 1px solid #5c6078;
        background-color: #1e2027;
        z-index: 1;
    }

    .number-hightlight {
        background-color: #27debf;
        box-shadow: 0px 0px 10px #27debf;
        opacity: 1;
        color: #171717;
        border: none !important;
    }

    .step-title-item {
        padding: 0.65rem;
    }

    .step {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .btn-container {
        margin-top: auto;
    }

    .radio-item-container {
        background-color: #1e2027;
        border-radius: 10px;
    }

    .radio-item-container:hover {
        cursor: pointer;
        /* border: 1px solid black; */
        box-shadow: 0.2px 0.2px 3px #c2c2c2;
    }

    .chain-icon {
        max-height: 6.7rem;
    }

    .radio-container {
        padding-right: 3rem;
    }

    .radio-shape {
        width: 20px;
        height: 20px;
        line-height: 20px;
        border-radius: 50%;
        border: 1px solid #c2c2c2;
    }

    .radio-selected {
        border-width: 5px;
    }

    .purpose-selection {
        border-radius: 10px;
        border: 4px solid #1e2027;
        padding: 0.5rem 2rem;
    }

    .btn-brand {
        color: #171717;
        font-weight: 500;
        background-color: #27debf;
        padding: 1% 5%;
    }

    .btn-brand-dark {
        color: lightgray;
        background-color: #1e2027;
        padding: 1% 5%;
    }

    .btn-brand-dark:hover {
        color: #27debf;
    }

    .address-field {
        color: lightgray;
        background-color: transparent;
        border: 3px solid #272727;
        border-radius: 5px;
        font-size: 1rem;
    }

    .address-field:active,
    .address-field:focus,
    .address-field:disabled {
        color: #c2c2c2;
        background-color: transparent;
        border-color: #272727;
        box-shadow: none !important;
    }

    .address-field-viewonly {
        padding-right: 3.55rem;
    }

    .token-address-spinner-container {
        margin-top: -2.45rem;
        padding-left: 93%;
    }

    .view-token-explorer {
        margin-left: -3rem;
    }

    .locking-token-for {
        padding-left: 1rem;
    }

    .floting-max-btn-container {
        position: absolute;
        left: 48%;
        top: 5rem;
        z-index: 1;

        /* min-width: 10rem; */
    }

    .floting-cliff-btn {
        border-radius: 10px;
        background-color: #27debf;
        min-width: 4.8rem;
    }

    .floting-cliff-container {
        position: absolute;
        left: 48%;
        top: 10.5rem;
        z-index: 1;
        max-width: 5rem;
    }

    .cliff-input-active {
        top: unset;
    }

    .unit-text {
        /* margin-top: -0.9rem; */
        /* padding-top: 0.5rem; */
        font-size: 0.5rem;
    }

    .floting-max-btn {
        border-radius: 10px;
        background-color: #27debf;
        min-width: 4.8rem;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type="number"] {
        -moz-appearance: textfield;
    }

    .view-vesting-table {
        color: #27debf;
        cursor: pointer;
    }

    .custom-control-input:checked ~ .custom-control-label::before {
        background-color: #27debf;
    }

    .view-vesting-table:hover {
        text-decoration: underline;
    }

    .vesting-statment {
        display: flex;
    }

    .vesting-statment h6 {
        font-size: 0.9rem;
    }

    .token-overview-table {
        color: lightgray;
        border-collapse: separate;
        border-spacing: 7px;
        min-width: 100%;
    }

    .token-overview-table > tbody > tr > td.title {
        /* min-width: 70%; */
        padding: 2% 5%;
        background-color: #1e2027;
        border-radius: 5px;
    }

    .token-overview-table > tbody > tr > td.value {
        /* min-width: 70%; */
        padding: 2% 8%;
        border: 3px #1e2027 solid;
        border-radius: 5px;
    }

    .blockchain-list {
        max-height: 23rem;
        /* overflow: scroll; */
        overflow-y: scroll;
        overflow-x: visible;
        padding: 2px;
    }

    .withdrawal-select {
        border-radius: 5px;
        border: 1px solid #292b33;
        padding: 0.9rem 1rem 0.9rem 1.8rem;
        /* margin-bottom: 1rem; */
    }

    .withdrawal-switch {
        border-radius: 10px;
    }

    .switch-selector {
        background-color: #292b33;
        padding: 0.65rem 2rem;
    }

    .switch-selector:hover {
        cursor: pointer;
    }

    .switch-selector-active {
        background-color: #27debf;
        color: #171717;
    }

    .switch-selector-myself {
        border-radius: 5px 0 0 5px;
    }

    .switch-selector-others {
        border-radius: 0 5px 5px 0;
    }

    .status-arrow {
        position: absolute;
        left: 3rem;
        margin-top: -0.6rem;
        width: 2.5rem;
    }

    .status-arrow-active {
        left: 2.1rem;
        margin-top: -1.5rem;
        width: 4.2rem;
    }

    .purpose-select {
        border-radius: 5px;
        align-content: flex-start;
        flex-wrap: wrap;
        border: 1px solid #292b33;
        padding: 0.9rem 1rem;
    }

    .purpose-item {
        margin: 0.3rem 0.2rem;
        padding: 0.8rem 1.7rem;
        border-radius: 4px;
        border: 2px solid #292b33;
        color: #c2c2c2;
        /* flex: 1 1 160px; */
        /* flex: auto; */
    }

    .purpose-item:hover {
        cursor: pointer;
        background-color: #1e2027;
        color: #27debf;
    }

    .purpose-item-active {
        background-color: #27debf;
        color: #171717;
        font-weight: 600;
    }

    .purpose-item-active:hover {
        background-color: #27debf;
        color: #171717;
    }

    .vault-sucess-step-container {
        padding: 5rem;
    }

    .vault-success-icon {
        width: 5rem;
    }

    .lock-amout {
        background-color: #1e2027;
        border-radius: 10px;
    }

    .lock-duration {
        background-color: #1e2027;
        border-radius: 10px;
    }

    .lock-detail-section .form-label {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 0;
    }

    .lock-detail-section .form-control {
        background-color: transparent;
        border-radius: 0;
        text-align: center;
        font-size: 1.15rem;
        border-color: transparent;
        color: #27debf;
        font-weight: 600;
    }

    .lock-detail-section .form-control:focus {
        border: 2px solid #1e2027;
        box-shadow: none;
    }

    .recipt-item {
        border: 2px solid #1e2027;
        padding: 0.5rem 1rem;
        border-radius: 5px;
    }

    .recipt-item-unlock-start {
        border: 5px solid #1e2027;
        border-radius: 0px 0px 10px 10px;
        margin-top: -7px;
        height: 3rem;
    }

    .dropdown-toggle,
    .dropdown-toggle:active,
    .dropdown-toggle:focus,
    .dropdown-toggle:hover {
        background-color: transparent !important;
        border-color: transparent !important;
        color: #27debf;
        font-size: 1.15rem;
        font-weight: 600;
    }

    .show > .btn-primary.dropdown-toggle {
        color: #27debf;
    }

    .vesting-statment {
        border-radius: 5px;
        border: 2px dashed #1e2027;
        padding: 0.5rem 1rem;
    }

    .dropdown-menu {
        min-width: fit-content;
    }

    .approve-lock-btn-container {
        width: 100%;
        margin: 1.5rem 7rem 0rem 4rem;
    }

    .approve-tx-hash {
        margin: 0rem 8rem;
    }

    .approve-tx-hash-link {
        font-size: 0.8rem;
        color: lightgray;
    }

    .approve-tx-hash-link:hover {
        color: #27debf;
    }

    .approve-btn {
        height: 2.5rem;
        min-width: 11rem;
        border-radius: 5px 1.25rem 1.25rem 5px;
        background-color: #27debf;
        padding-right: 0;
        border: none;
        color: lightgray;
        font-weight: 600;
    }

    .approve-btn:disabled {
        cursor: not-allowed;
    }

    .approve-btn-content {
        height: 100%;
        color: #171717
    }

    .approve-btn-right-number {
        height: 2.5rem;
        width: 2.5rem;
        color: #27debf;
        background-color: white;
        border-radius: 1.25rem;
        line-height: 2.5rem;
        font-weight: 600;
    }

    /* .lock-btn {
    height: 2.5rem;
    width: 10rem;
    border-radius: 1.25rem 5px 5px 1.25rem;
    background-color: #27DEBF;
    padding-right: 0;
    border: none;
} */

    .lock-btn {
        height: 2.5rem;
        min-width: 10rem;
        border-radius: 1.25rem 5px 5px 1.25rem;
        background-color: #27debf;
        padding-left: 0;
        border: none;
        color: #171717;
        font-weight: 600;
    }

    .lock-btn-content {
        height: 100%;
    }

    .lock-btn:disabled {
        height: 2.5rem;
        border-radius: 1.25rem 5px 5px 1.25rem;
        background-color: #1e2027;
        padding-left: 0;
        border: none;
        color: lightgray;
    }

    .lock-btn:disabled > .lock-btn-content > .lock-btn-left-number {
        height: 2.5rem;
        width: 2.5rem;
        color: lightgray;
        background-color: #1e2027;
        border-radius: 1.25rem;
        line-height: 2.5rem;
        font-weight: 600;
        border: 1px #5c6078 solid;
    }

    .lock-btn > .lock-btn-content > .lock-btn-left-number {
        height: 2.5rem;
        width: 2.5rem;
        color: #27debf;
        background-color: white;
        border-radius: 1.25rem;
        line-height: 2.5rem;
        font-weight: 600;
    }

    .recipt-view h6 {
        font-size: 0.8rem;
    }

    .edit-first-unlock {
        background-color: #27debf;
        color: #171717;
        // border: 1px solid #27debf;
        padding: 0.15rem 0.7rem;
        border-radius: 5px;
        cursor: pointer;
    }

    .react-datepicker__input-container {
        input {
            line-height: 0;
            font-size: 0.75rem;
            background-color: #171717;
            color: lightgray;
            width: 14rem;
        }
    }

    .react-datepicker {
        background-color: #191919;
    }

    .react-datepicker__current-month, .react-datepicker__day-name, .react-datepicker-time__header, .react-datepicker__day {
        color: lightgray;
    }

    .react-datepicker__header {
        background-color: #1e2027;
        color: lightgray !important;
    }

    .react-datepicker__month-container {
        background-color: #191919;
        color: lightgray !important;
    }
    
    .react-datepicker__day--keyboard-selected {
        background-color: #27debf;
        color: #171717;
    }

    .react-datepicker__day:hover {
        background-color: #27debf;
        color: #171717;
    }

    .react-datepicker__day--selected {
        background-color: #27debf;
        color: #171717;
    }

    .react-datepicker__time-list-item {
        color: lightgray;
    }

    .react-datepicker__time {
        background-color: #191919;
    }

    .react-datepicker__time-list-item--selected {
        background-color: #27debf !important;
        color: #171717 !important;
    }

    .react-datepicker__time-list-item:hover {
        background-color: #27debf !important;
        color: #171717 !important;
    }

    .react-datepicker__navigation-icon::before {
        border-color: lightgray
    }

    .react-datepicker__day--disabled {
        color: gray;
    }
    
    .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
        border-top-color: #191919;
        border-bottom-color: #191919;
    }


    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
        border-top-color: #191919;
        border-bottom-color: #191919;
    }
    

    .connector-line {
        width: 100%;
        height: 50%;
        border-bottom: 5px #1e2027 dashed;
    }

    .connector-line-sucess {
        border-bottom-color: #27debf;
    }

    .btn-icon {
        height: 1rem;
        margin-right: 0.5rem;
    }

    .img-btn-lock-back {
        width: 1rem;
        height: 1rem;
    }

    .btn-lock-back {
        width: 2.5rem;
        height: 2.5rem;
        padding: 0.725rem;
        border-radius: 1.5rem;
        margin-top: 1.5rem;
        /* border: 1px solid #27debf; */
        box-shadow: -0.7px 1px 3px 1px #27debf;
    }

    .btn-brand-border {
        color: lightgray;
        border: 1px lightgray solid;
        padding: 1% 3%;
    }

    /* .lock-token .form-control.is-invalid {
    padding-right: initial;
} */

    @media (max-width: 576px) {
        .create-lock-form-content.content-padding {
            padding: 0 2%;
        }

        .create-lock-form-content h2 {
            font-size: 0.875rem;
        }

        .create-lock-form-content h4 {
            font-size: 1.175rem;
        }

        .create-lock-form-content h5 {
            font-size: 1rem;
        }

        .create-lock-form-content h6 {
            font-size: 0.65rem;
        }

        .create-lock-form-content p {
            font-size: 0.775rem;
        }

        .left-section {
            display: none;
        }

        .right-section {
            margin: auto;
            padding: 1rem;
        }

        .radio-item-container {
            padding: 1rem 0.5em;
        }

        .chain-icon {
            max-height: 4rem;
        }

        .radio-container {
            padding-right: 0.5rem;
        }

        .btn-container {
            margin-top: 1.2rem;
        }

        .step-title {
            margin-bottom: 1.5rem;
        }

        .floting-max-btn {
            display: none;
        }

        .locking-token-for {
            padding-left: 0.5rem;
        }

        .floting-cliff-container {
            display: none;
        }

        .lock-detail-section .form-label {
            font-size: 0.9rem;
        }

        .status-arrow {
            left: 0.45rem;
        }

        .status-arrow-active {
            left: -0.4rem;
        }

        .switch-selector {
            padding: 0.65rem 1.3rem;
        }

        .purpose-item {
            padding: 0.5rem 0.7rem;
        }

        .lock-type-selector {
            display: flex;
            flex-direction: column;
        }

        .vesting-statment {
            flex-direction: column-reverse;
        }

        .view-vesting-table {
            margin-bottom: 0.5rem !important;
            text-decoration: underline;
        }

        .approve-lock-btn-container {
            margin: 1.5rem 2rem 0rem 2rem;
            flex-direction: column;
        }

        .approve-btn {
            min-width: 10rem;
        }

        .connector-line {
            border: none;
            margin-top: 1rem;
        }

        .approve-btn-right-number,
        .lock-btn-left-number {
            display: none;
        }

        .approve-btn,
        .lock-btn,
        .lock-btn:disabled {
            border-radius: 5px;
        }

        .blockchain-list {
            max-height: max-content;
        }
    }
}
