.home-page {
    .brand-content {
        height: 101vh;
        padding-top: 4rem;
    }

    .brand-bg-img {
        background-image: url("./images/brand-bg.png");
        background-size: cover;
    }

    .brand-img {
        width: 90%;
    }

    .btn-brand {
        color: #171717;
        background-color: #27debf;
        font-weight: 500;
        padding: 1% 5%;
    }

    .audited-by-container {
        color: #c2c2c3;
        display: flex;
        align-items: center;
        margin-left: 3px;
    }

    .audit-logo {
        text-decoration: none;
    }

    .btn-brand-border {
        color: lightgray;
        border: 1px lightgray solid;
        padding: 1% 3%;
    }

    .public-sale-link {
        color: #27debf;
        text-decoration: underline;
        font-weight: 700;
        letter-spacing: 0.055rem;
    }

    .public-sale-link:hover {
        color: #27debf;
    }

    .btn-brand-border:hover {
        color: #27debf;
    }

    .title-area-bgcolor {
        background-color: #171717;
    }

    .background-wrapper {
        background-image: url("./images/home_background.svg");
        background-size: 100%;
    }

    .about-us-left-grill {
        background-image: url("./images/about-us_left-grill.svg");
        background-repeat: no-repeat;
        padding-top: 5%;
        background-size: 30%;
        background-position-x: -13%;
    }

    .title-right-grill {
        background-image: url("./images/right-grill.svg");
        background-repeat: no-repeat;
        background-size: 30%;
        background-position-x: right;
        background-position-y: center;
    }

    .title-left-grill {
        background-image: url("./images/left-grill.svg");
        background-repeat: no-repeat;
        background-size: 30%;
        background-position-x: left;
        background-position-y: center;
    }

    .media-section {
        background-color: #131313;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        .title-container {
            // background-color: green;
            margin-right: 2rem;
        }
        .content-padding {
            padding: 0 1% 0 4%;
        }
        .media-title {
            color: lightgray;
        }
        .media-logo {
            filter: grayscale(1) invert(0.2);
        }
        .media-logo:hover {
            filter: grayscale(0%);
        }
    }

    /* .feature-card {
        background-color: red;
    } */

    .product-card-reverse-mobile {
        display: none;
    }

    .product-card-reverse {
        img {
            width: 24rem;
        }
        //
    }

    .product-card-first {
        margin-bottom: 3rem;
        margin-top: -1rem;
    }

    .product-card-second {
        margin-bottom: -0.5rem;
    }

    .product-card {
        img {
            width: 28rem;
            // margin-bottom: 3rem;
        }
    }

    .vesting-live-container {
        width: fit-content;
        background-color: #27debf;
        padding: 0.05rem 0.5rem;
        border-radius: 0.2rem;
        position: absolute;
        margin-left: 0.5rem;
        margin-top: -1.5rem;
        .vesting-live {
            font-size: 0.7rem;
            font-weight: 700;
        }
        .live-loader {
            height: 0.5rem;
            width: 0.5rem;
            margin-right: 0.3rem;
            background-color: white;
        }
    }

    .staking-fix {
        margin-top: -0.5rem;
    }
    

    .lp-live-parent-container {
        width: 100%;
        justify-content: flex-end;
    }

    .center-fix {
        justify-content: center;
    }

    .lp-live-container {
        // width: fit-content;
        background-color: #27debf;
        padding: 0.05rem 0.5rem;
        border-radius: 0.2rem;
        position: absolute;
        margin-left: auto;
        margin-right: 0.5rem;
        margin-top: -1.9rem;
        .lp-live {
            font-size: 0.7rem;
            font-weight: 700;
        }
        .live-loader {
            height: 0.5rem;
            width: 0.5rem;
            margin-right: 0.3rem;
            background-color: white;
        }
    }

    .yeild-fix {
        margin-top: -0rem;
    }

    .feature-card > img {
        height: 8rem;
    }

    .feature-card-body {
        background-color: #151515;
        margin-top: -4rem;
        padding: 5rem 1.5rem 1rem 1.5rem;
        border: 1.5px grey solid;
        min-height: 12rem;
        margin-bottom: 40px;
        border-radius: 5px;
        h5 {
            margin-top: 0.5rem;
        }
    }

    .feature-card:hover .border-img {
        transform: rotate(360deg);
        transition: all 15s linear;
    }

    .border-container {
        // margin-top: -9rem;
        margin-top: -9.5rem;
        padding-top: 1.3rem;
        background-image: url("./images/animation.gif");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 11rem;
        // z-index: -1;
    }

    .border-img {
        transition: all 0s;
        width: 8rem;
    }

    .token-summary-img {
        width: 90%;
    }

    .token-summary-table {
        color: lightgray;
        border-collapse: separate;
        border-spacing: 10px;
        min-width: 100%;
    }

    .token-summary-table > tbody > tr > td.title {
        min-width: 70%;
        padding: 2% 8%;
        background-color: #131313;
        border: 2px solid #101010;
        border-radius: 5px;
    }

    .token-summary-table > tbody > tr > td.value {
        min-width: 70%;
        padding: 2% 8%;
        border: 3px #272727 solid;
        border-radius: 5px;
    }

    .utility-card-body > img {
        height: 7rem;
        margin-bottom: 2rem;
    }

    .utility-card-body {
        background-color: #171717;
        border: 3px #343644 dashed;
        padding: 1rem 2rem 1rem 2rem;
        min-height: 14rem;
        margin-bottom: 30px;
        border-radius: 5px;
    }

    .advisor-card > img {
        width: 15rem;
        border-radius: 5px;
    }

    .advisor-card-body {
        position: relative;
        background-color: #151515;
        border: 1.5px #808080 solid;
        border-top: none;
        margin: -2.5rem auto;
        padding: 1rem 1rem 1rem 1rem;
        min-height: 5rem;
        margin-bottom: 30px;
        border-radius: 5px;
        width: 12rem;
        z-index: 2;
        h5 {
            margin-bottom: 0;
        }
    }

    .advisor-card-body > .advisor-description {
        line-height: 1;
        margin-bottom: 0;
        font-size: 80%;
    }

    .advisor-card-body > .advisor-description-position {
        font-size: 90%;
    }

    .section-margin-hero {
        margin-bottom: 0rem;
    }

    .section-margin {
        margin-bottom: 5rem;
    }

    .section-margin-4rem {
        margin-bottom: 4rem;
    }

    .section-header {
        margin-bottom: 3rem;
    }

    .section-sub-title {
        color: lightgray;
        opacity: 0.05;
        font-size: 5.5rem;
        position: absolute;
        left: 50%;
        text-transform: uppercase;
        white-space: nowrap;
        transform: translate(-50%, 0%);
    }

    .multichain-white-img {
        filter: brightness(0) invert(1);
    }

    #outerContainer {
        position: absolute;
        margin-top: -35.5rem;
        width: 100%;
    }

    #container {
        // width: 600px;
        width: 100%;
        height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        position: relative;
    }

    .item {
        background-color: #171717;
        z-index: 100;
        border-radius: 50%;
    }

    .circle {
        border-radius: 50%;
        background-color: #27debf;
        width: 130px;
        height: 130px;
        position: absolute;
        opacity: 0;
        animation: scaleIn 4s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
    }

    @keyframes scaleIn {
        from {
            transform: scale(0.5, 0.5);
            opacity: 0.2;
        }
        to {
            transform: scale(2.5, 2.5);
            opacity: 0;
        }
    }

    /* // Extra small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up) */
    @media (max-width: 576px) {
        .content-hero-padding {
            padding: 0 7%;
        }
        
        .audited-by-container {
            flex-direction: column;
            align-items: start;
            .audit-by-title {
                margin-bottom: 0.3rem !important;
            }
            .certik-audit-logo {
                width: 7rem;
            }
            .immune-logo {
                margin-top: 0.75rem;
                margin-left: 0 !important;
            }
            img {
                width: 7.4rem;
            }
        }

        .media-section {
            .title-container {
                // background-color: green;
                margin-right: 0rem;
                margin-bottom: 0.5rem;
            }

            .content-padding {
                padding: 0% 4%;
            }

            .media-section-flex {
                flex-direction: column;
            }

            .mobile-small {
                max-width: 70%;
            }
        }

        .brand-bg-img {
            // background: url("./images/brand-bg-lwo-opacity.svg");
            background-size: cover;
        }

        .feature-card-body {
            padding: 4.5rem 0.5rem 0.5rem 0.5rem;
            margin-top: -3.5rem;
            min-height: unset;
            h5 {
                font-size: 1.2rem;
            }
            p {
                font-size: 0.85rem;
            }
        }

        .lp-live-container {
            margin-top: 0rem;
        }

        .fund-raise-live-fix {
            margin-top: -0.5rem;
            margin-left: 0.5rem;
        }

        .vesting-live-container {
            margin-top: -0.5rem;
        }

        .feature-card > img {
            height: 7rem;
        }

        .feature-card:focus .border-img {
            transform: rotate(360deg);
            transition: all 15s linear;
        }

        .border-container {
            margin-top: -7rem;
            margin-top: -8.5rem;
            padding-top: 1.5rem;
            background-size: 9.5rem;
        }

        .border-img {
            transition: all 0s;
            width: 7rem;
        }

        .brand-content {
            height: fit-content;
            padding-top: 0;
        }

        .brand-title {
            margin-top: 10rem;
        }

        .display-3 {
            font-size: 2.7rem;
        }

        .backer-logo {
            margin-bottom: 0.95rem;
        }

        .mobile-small {
            max-width: 68%;
        }

        .mobile-left-small {
            max-width: 55%;
        }

        .brand-img-conatiner {
            margin: 5rem auto;
        }

        .brand-img {
            width: 95%;
            padding-right: 3%;
        }

        .section-margin {
            margin-bottom: 3rem;
        }

        .about-us-img-container {
            margin-bottom: 3rem;
        }

        .community-img-container {
            margin-bottom: 3rem;
        }

        .section-sub-title {
            font-size: 0rem;
            display: none;
        }

        .title-right-grill {
            background-size: 70%;
            background-position-x: 150%;
        }

        .title-left-grill {
            background-size: 70%;
            background-position-x: -50%;
        }

        .token-summary-img-container {
            margin-bottom: 3rem;
        }

        .sub-title {
            font-size: 1.25rem;
        }

        .section-margin {
            margin-bottom: 0rem;
        }

        .advisors-section {
            .content-padding {
                padding: 0 3%;
            }
        }

        .advisor-card > img {
            // width: 9rem;
            border-radius: 5px;
        }

        .advisor-card-body {
            position: relative;
            background-color: #151515;
            border: 1.5px #808080 solid;
            border-top: none;
            margin: -1.2rem -4%;
            padding: 0.5rem 0.5rem 0.75rem 0.5rem;
            min-height: fit-content;
            margin-bottom: 30px;
            border-radius: 5px;
            width: 108%;
            z-index: 2;
            h5 {
                margin-bottom: 0;
                font-size: 0.9rem;
            }
            p {
                font-size: 0.7rem;
            }
        }
    }

    @media (max-width: 1200px) {
        #outerContainer {
            display: none;
        }
    }

    /* // Medium devices (tablets, 768px and up) */
    @media (min-width: 768px) {
        // .lp-live-container {
        //     margin-top: 0rem;
        // }

        // .vesting-live-container {
        //     margin-top: -0.5rem;
        // }
    }

    /* // Large devices (desktops, 992px and up) */
    @media (max-width: 992px) {
        .product-card-first {
            margin-bottom: 2rem;
            margin-top: 0rem;
        }

        .product-card-second {
            margin-bottom: 2rem;
        }

        .product-card-reverse-mobile {
            display: block;
            margin-bottom: 2rem;
        }

        .product-card-reverse {
            display: none !important;
        }
    }

    /* // Extra large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {
    }
}
