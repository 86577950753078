.footer {
    background-color: #161616;
    background-image: url("./footer_bg.png");
    background-size: cover;
}

.fooetr-logo {
    width: 9.5rem;
}

.social-icon {
    height: 1rem;
}

.info {
    text-decoration: none;
    color: lightgray;
    padding: 0.2rem 2.5rem;
}

.info:hover, .info:active {
    text-decoration: none;
    color: lightgray;
}

.info-litepaper {
    border-right: 1px solid white;
}

.divider {
    border-top: 1px solid white;
    opacity: 0.18;
}

.social-icons > a, .social-icons > a:hover {
    text-decoration: none;
}

.btn-apply-for-ido {
    background-color: #27DEBF;
    border-radius: 25px;
    text-decoration: none;
}

.btn-apply-for-ido:hover {
    text-decoration: none;
}

.btn-apply-for-ido-left {
    height: 45px;
    width: 45px;
    border: 2px #27DEBF solid;
    border-radius: 25px ;
    background-color: #151515 !important;
    /* padding: 0rem 1rem; */
    /* padding: 6px 10px; */
}

.btn-apply-for-ido-right {
    height: 45px;
    color: #171717;
    background-color: #27DEBF;
    padding: 0px 20px 0px 5px;
    border-radius: 0px 25px 25px 0px;
    line-height: 28px;
    font-size: 1.15rem;
}

.footer-link {
    color: white
}

.footer-link:hover {
    color: #27DEBF;
}