.modal-header {
    border-bottom: none;
    background-color: #1e2027;
}

.modal-header>.close {
    color: white
} 

.metamask-img-padding-fix {
    padding: 0.835rem 1rem !important;
}

.wallet-item {
    background-color: #1e2027;
    border: 1px solid #5C6078;
    margin: 0.75rem 0.3rem;
    
    border-radius: 10px;
    min-height: 3.5rem;
    align-items: center;
    transition: all 2s ease-out;
}

.connect-details-container {
    min-height: 3.5rem;
    padding: 1.2rem 1rem;
    cursor: pointer;
    transition: all 2s ease-out;
}

.connector-name {
    font-size: 1.1rem;
}

.network-container {
    margin: 0.5rem 0rem 1rem 0rem;
}

.selected-network {
    /* background-color: seagreen !important; */
    /* border: 2px solid #6c7088 !important */
    background-color: #1e2027;
}

.network-inner-item {
    /* background-color: #1e2027; */
    border: 2px solid #171717;
    border-radius: 10px;
}

.chain-icon-img {
    margin-top: 0.85rem;
    position: relative;
}

.chain-name-below-img {
    margin-bottom: 0.85rem !important;
    font-size: 0.8rem;
}

.selected-chain-icon {
    position: absolute;
    bottom: -2px;
    right: -2px;
}

.selected-chain-icon > img {
    border: 1px solid black;
    border-radius: 50%;
}

.wallet-connect-btn {
    padding: 0.45rem 5rem !important;
    font-size: 14px !important;
}

.network-inner-item:hover {
    /* background-color: red; */
    background-color: #1e2027;
    cursor: pointer;
}

.border-bottom-curvy {
    border-radius: 10px;
    border-bottom: 1px solid #5C6078;
}

.wallet-item:hover {
    background-color: inherit
}