.vault-list-container h2 {
    font-size: 1rem;
}

.vault-list-container h4 {
    font-size: 1.3rem;
}

.vault-list-container h5 {
    font-size: 1.2rem;
}

.vault-list-container h6 {
    font-size: 0.75rem;
}

.vault-list-container p {
    font-size: 0.9rem;
}

.vault-list-container {
    .vault-background {
        background-image: url(./images/vault_background.svg);
    }

    .page-caption {
        color: #9a9a9b;
    }

    .feature-card:hover .border-img {
        transform: rotate(360deg);
        transition: all 15s linear;
    }

    .border-container {
        margin-top: -11.5rem;
        padding-top: 2.5rem;
        background-image: url("./images/animation.gif");
        background-position: center top;
        background-repeat: no-repeat;
        background-size: 14rem;
    }

    .border-img {
        transition: all 0s;
        width: 9rem;
    }

    // .btn-vault {
    //     background: var(--unnamed-color-27debf) 0% 0% no-repeat padding-box;
    //     background: #27debf 0% 0% no-repeat padding-box;
    //     border-radius: 5px;
    //     opacity: 1;
    //     padding: 10px 76px;
    //     font-weight: bold;
    //     font-size: 16px/22px;
    //     letter-spacing: 0.32px;
    //     text-transform: uppercase;
    // }

    .vault-btn {
        .btn-brand {
            color: #171717;
            font-weight: 500;
            background-color: #27debf;
            // padding: 1% 5%;
        }
        .btn-brand-border {
            color: lightgray;
            border: 1px lightgray solid;
            // padding: 1% 3%;
        }
        .btn-brand,
        .btn-brand-border {
            padding: 0.5rem 1rem;
            font-weight: 700;
        }
    }

    .page-main {
        margin: 2.5rem 9% auto 9%;
        background: #1e2027 0% 0% no-repeat padding-box;
        border-radius: 10px;
        // margin-top: 2.5rem;
    }

    .vault-card {
        justify-content: center;
        margin-top: 3.75rem;
    }

    .vault-card-title {
        text-align: center;
        font: 22px/26px;
        letter-spacing: 0.44px;
        font-weight: 600;
        color: lightgray;
        opacity: 1;
        margin-top: 2.3rem;
    }

    .vault-card-caption {
        letter-spacing: 0.28px;
        color: #9a9a9b;
        opacity: 1;
        margin-top: 1rem;
        margin-bottom: 4.4rem;
    }

    .borderline-right {
        border-right: 1px solid rgba(255, 255, 255, 0.08);
    }

    .data-card-holder {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 15px;
    }

    // .search-container {
    //     width: 85%;
    // }

    .search-card {
        background: #171717 0% 0% no-repeat padding-box;
        border-radius: 10px 10px 0px 0px;
        opacity: 1;
        margin-top: 1.5rem;
    }

    .search-title {
        text-align: center;
        letter-spacing: 0.48px;
        font-weight: 600;
        color: lightgray;
        opacity: 1;
        margin: 2.5rem auto 0.825rem auto;
    }

    .search-input {
        border: 1px solid #1e2027;
        width: 80%;
        border-radius: 5px;
        opacity: 1;
        background-color: #1e2027;
        margin-bottom: 2rem;
    }

    .search-input:focus {
        background-color: #1e2027;
        color: lightgray;
    }

    .feature-container {
        margin-top: 2rem;
        background-color: #171717;
    }

    .error-message-container {
        color: lightgray;
        padding: 5rem;
    }

    .feature-card > img {
        height: 9rem;
    }

    .feature-card-body {
        background-color: #151515;
        margin-top: -4.5rem;
        padding: 5rem 2rem 1rem 2rem;
        border: 1.5px grey solid;
        min-height: 10rem;
        margin-bottom: 40px;
        border-radius: 5px;
        h5 {
            margin-top: 0.5rem;
        }
    }

    @media (min-width: 1281px) and (max-width: 1440px) {
    }

    @media (min-width: 1025px) and (max-width: 1280px) {
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        .borderline-right {
            border-bottom: 1px solid rgba(255, 255, 255, 0.08);
            border-right: 0px;
        }

        .data-card-holder {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 15px;
        }
    }

    @media (max-width: 576px) {
        .data-card-holder {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 15px;
        }

        .borderline-right {
            border-bottom: 1px solid rgba(255, 255, 255, 0.08);
            border-right: 0px;
        }

        .feature-card > img {
            height: 8rem;
        }

        .border-img {
            transition: all 0s;
            width: 8rem;
        }

        .border-container {
            margin-top: -8rem;
            margin-top: -9.5rem;
            padding-top: 1.5rem;
            background-size: 11.5rem;
        }
    }
}

@media (max-width: 576px) {
    .vault-list-container .content-padding {
        padding: 0 4%;
    }

    .vault-list-container .feature-card-body {
        min-height: unset;
    }
    .vault-list-container h2 {
        font-size: 0.875rem;
    }

    .vault-list-container h4 {
        font-size: 1.175rem;
    }

    .vault-list-container h5 {
        font-size: 1rem;
    }

    .vault-list-container h6 {
        font-size: 0.65rem;
    }

    .vault-list-container p {
        font-size: 0.775rem;
    }

    .vault-list-container {
        .vault-btn {
            .btn-brand {
                padding: 0.5rem 0.6rem;
                font-size: 0.8rem;
            }
            .btn-brand-border {
                padding: 0.5rem 0.6rem;
                font-size: 0.8rem;
            }
        }

        .search-title {
            margin: 2rem auto 1rem auto;
            font-size: 1.2rem;
        }

        .search-input {
            margin-bottom: 2rem;
        }

        .borderline-right {
            border-bottom: 1px solid rgba(255, 255, 255, 0.08);
            border-right: 0px;
        }

        // .data-card-holder {
        //     display: grid;
        //     grid-template-columns: 1fr;
        //     grid-gap: 15px;
        // }

        .page-main {
            margin: 2rem 3% auto 3%;
        }

        .btn-center {
            justify-content: center !important;
        }
    }
}
