.vesting-vault-explorer-container {
    // .range-slider:read-only {
    .range-slider:hover {
        cursor: none;
    }
    // }
    .badge-rotate {
        transform: rotate(-90deg);
    }
    .btn-brand-border {
        color: lightgray;
        border: 1px lightgray solid;
        // padding: 1% 3%;
    }
    .progress-bar {
        height: 20px;
        background-color: transparent;
        margin-bottom: 0.2rem;
    }
    .vault-content {
        padding: 0 9%;
    }
    .project-details-container {
        background-color: #1e2027;
        padding: 1rem;
        border-radius: 10px;

        .project-details {
            .project-icon {
                width: 100px;
            }
            background-color: #191919;
            padding: 1rem;
            border-radius: 10px;
            margin-right: 1rem;
        }
        .lock-overview {
            background-color: #191919;
            padding: 1rem;
            border-radius: 10px;
        }
    }
    .track-my-lock {
        padding: 0.2em 1em;
    }

    .search-card:focus-within {
        width: 25rem;
    }

    .search-card {
        margin-right: 1rem
    }

    .search-input {
        border: 1px solid #1e2027;
        border-radius: 5px;
        opacity: 1;
        width: 100%;
        background-color: #1e2027;
        transition: all 0.3s ease-in-out;
    }

    .search-input:focus {
        background-color: #1e2027;
        color: lightgray;
        width: 100%;
        // width: 25rem;
    }
    .lock-list-container {
        background-color: #1e2027;
        padding: 1rem;
        border-radius: 10px;
        .list-title {
            background-color: #191919;
            padding: 0.9rem 1.2rem;
            border-radius: 10px;
            margin-bottom: 1rem;
        }
        .track-my-lock-mobile {
            display: none;
        }

        .list-item {
            background-color: #191919;
            padding: 1.2rem 3rem;
            border-radius: 10px;
            margin-bottom: 1rem;
            .floting-status {
                button {
                    background: transparent;
                }
                .status-card {
                    margin-left: -2.3rem;
                    .img-contaniner {
                        background-color: #191919;
                        width: 2.4rem;
                        height: 2.4rem;
                        border: 2px solid #27debf;
                        border-radius: 5px 0 0 5px;
                        .img {
                            padding-top: 0.4rem;
                            width: 1.6rem;
                            height: 1.6rem;
                            margin: auto;
                        }
                    }
                    .text-container {
                        height: 2.4rem;
                        background-color: #27debf;
                        border-radius: 0 5px 5px 0;
                        border: 2px solid #27debf;
                        min-width: 7rem;
                        padding: 0.5rem;
                        color: #171717;
                    }
                }
            }

            .withdraw-tx-link {
                margin-left: -5.6rem;
            }

            .withdrawal-address {
                a {
                    color: lightgray;
                }
                a:hover {
                    text-decoration: none;
                }
            }
        }
    }

    @media (max-width: 576px) {
        h5 {
            font-size: 0.9rem !important;
        }
        h2 {
            font-size: 1.2rem !important;
        }
        h6 {
            font-size: 0.8rem !important;
        }
        .vault-content {
            padding: 0 2%;
        }
        .project-details-container {
            font-size: 0.85rem;
            flex-direction: column;
            margin-right: 0;
            .project-details {
                .project-icon {
                    width: 50px;
                }
                margin-bottom: 1rem;
            }
        }
        .title-filter-container {
            flex-direction: column;
        }
        .lock-list-container {
            .track-my-lock-desktop {
                display: none;
            }
            .search-card-container {
                width: 100%;
            }
            .search-card {
                margin-top: 1rem;
                width: 100%;
                margin-right: 0rem
            }
            .track-my-lock-mobile {
                display: flex;
                font-size: 0.8rem;
                white-space: nowrap;
            }
            .list-item {
                padding: 1rem 1rem 1rem 1rem;
                .details-section {
                    font-size: 0.8rem;
                    padding-bottom: 1rem;
                    border-bottom: 1px solid #1e2027;
                    margin-bottom: 1rem;
                }
                .floting-status {
                    margin-top: 0 !important;
                    button {
                        background: transparent;
                    }
                    .status-card {
                        margin-left: -1.5rem;
                        .img-contaniner {
                            width: 2rem;
                            height: 2rem;
                            .img {
                                padding-top: 0.2rem;
                                width: 1.3rem;
                                height: 1.3rem;
                            }
                        }
                        .text-container {
                            display: flex;
                            align-items: center;
                            height: 2rem;
                            min-width: 5rem;
                            padding: 0.25rem;
                            h6 {
                                // margin: auto;
                            }
                        }
                    }
                }
            }
        }
    }
}
