.vesting-landing-page {
    .brand-bg-img {
        background-image: url("./images/brand-bg.png");
        background-size: cover;
    }
    .btn-brand {
        color: #171717;
        background-color: #27debf;
        font-weight: 500;
        padding: 1% 5%;
    }
    .btn-brand-border {
        color: lightgray;
        border: 1px lightgray solid;
        padding: 1% 3%;
    }
    .hero-content {
        padding-top: 5rem;
        height: 100vh;
        .hero-row {
            margin: auto;
        }
        .hero-title {
            padding-left: 10%;
            padding-top: 2%;
            .sub-title-typing {
                font-size: 3rem;
            }
        }
        // .brand-img-conatine {
        //     padding-right: -15px;
        //     margin-right: -15px;
        // }
        .hero-img {
            max-height: 65vh;
            margin-top: -6rem;
            margin-right: -15px;
        }
        .audited-by-container {
            color: #c2c2c3;
            display: flex;
            align-items: center;
            margin-left: 3px;
        }

        .audit-logo {
            text-decoration: none;
        }
    }
    .background-wrapper {
        background-image: url("./images/home_background.svg");
        background-size: 100%;
    }
    .title-right-grill {
        background-image: url("./images/right-grill.svg");
        background-repeat: no-repeat;
        background-size: 30%;
        background-position-x: right;
        background-position-y: center;
    }
    .title-left-grill {
        background-image: url("./images/left-grill.svg");
        background-repeat: no-repeat;
        background-size: 30%;
        background-position-x: left;
        background-position-y: center;
    }
    .section-header {
        padding-bottom: 3rem;
    }
    .section-margin {
        margin-bottom: 5rem;
    }
    .section-margin-trusted {
        padding-bottom: 4rem;
    }
    .section-sub-title {
        color: lightgray;
        opacity: 0.05;
        font-size: 5.5rem;
        position: absolute;
        left: 50%;
        text-transform: uppercase;
        white-space: nowrap;
        transform: translate(-50%, 0%);
    }
    .media-section {
        background-color: #131313;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        margin-bottom: 2rem;
        .chain-logo-container {
            width: 100%;
            margin: 0 2rem 0 1rem;
        }
        .title-container {
            // background-color: green;
            margin-right: 2rem;
        }
        .content-padding {
            padding: 1rem 1% 1rem 4%;
        }
        .media-title {
            color: lightgray;
        }
        .mobile-small {
            max-width: 65%;
        }
        .desktop-large {
            max-width: 110%;
        }
        .media-logo {
            // filter: grayscale(1) invert(0.2);
        }
        .media-logo:hover {
            filter: grayscale(0%);
        }
    }
    .type-of-vault-section {
        .content-padding {
            padding: 0 1% 0 4%;
            justify-content: center;
        }
        .card-container {
            width: 75%;
        }
        .vault-card {
            display: flex;
            margin: auto;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 16.5rem;
            height: 16.5rem;
            background: linear-gradient(45deg, #171717, #181c1e);
            border: 2px solid rgb(90, 90, 90);
            padding: 2.5rem 2rem 2rem 2rem;
            .vault-card-title {
                margin: 0.7rem 0 0.4rem 0;
            }
            .vault-card-text {
                text-align: center;
                color: lightgray;
                margin-bottom: 0;
                // padding: 5% 10%;
            }
        }
        .intergrated-chain {
            margin-top: 3rem;
            color: lightgray;
        }
        .logo-container {
            margin-top: 1rem;
            .chain-icon {
                margin: 0 0.8rem 0 0.8rem;
            }
        }
        // justify-content: space-around;
    }
    .offering-section {
        .offering-card-container {
            .item {
                flex: 1;
            }
        }
        .offering-card-container-1 {
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: 2.3rem 0 2.3rem 2.3rem;
            background-image: url("./images/bottom-left-square.png");
            background-position: bottom left;
            background-repeat: no-repeat;
            background-size: 10rem;
        }
        .offering-card-container-2 {
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: 2.3rem 2.3rem 2.3rem 0;
            background-image: url("./images/top-right-square.png");
            background-position: top right;
            background-repeat: no-repeat;
            background-size: 10rem;
        }
        .offering-card {
            flex: 1;
            border: 2px solid black;
            background-color: #181c1e;
            padding: 2rem;
            border-radius: 4px;
        }
        .gradient-line {
            flex: 1;
            height: 2px;
            background: linear-gradient(to right, #ffffff, #171717);
        }
        .top-bottom-border {
            padding: 1rem 0 1rem 0;
            border-top: 2px solid #262626;
            border-bottom: 2px solid #262626;
        }
        .propositions-list {
            padding-left: 0.2rem;
        }
        .propositions-list > li {
            margin: 0;
            padding: 0.2rem 0px 0.2rem 1.5rem;
            list-style: none;
            background-image: url("./images/li-diamond_small.png");
            background-repeat: no-repeat;
            background-position: left center;
            background-size: 1rem;
            color: lightgray;
            font-size: 1.1rem;
        }
    }
    .token-vesting-section {
        .description {
            border: 2px solid black;
            background-color: #181c1e;
            padding: 1.7rem 1rem 2rem 1rem;
            border-radius: 4px;
            margin-right: 2rem;
            margin-left: 1.5em;
        }
        .description-list > li {
            margin: 0;
            padding: 0.75rem 0px 0.75rem 3rem;
            list-style: none;
            background-image: url("./images/li-arrow.png");
            background-repeat: no-repeat;
            background-position: left center;
            background-size: 1.7rem;
            color: lightgray;
            font-size: 1.2rem;
        }
        .gradient-line {
            flex: 1;
            height: 2px;
            background: linear-gradient(to right, #ffffff, #171717);
        }
    }
    .lp-lock-section {
        .description {
            border: 2px solid black;
            background-color: #181c1e;
            padding: 1.7rem 1rem 2rem 1rem;
            border-radius: 4px;
            margin-right: 2rem;
            margin-left: 1.5em;
        }
        .description-list > li {
            margin: 0;
            padding: 0.75rem 0px 0.75rem 3rem;
            list-style: none;
            background-image: url("./images/li-arrow.png");
            background-repeat: no-repeat;
            background-position: left center;
            background-size: 1.7rem;
            color: lightgray;
            font-size: 1.2rem;
        }
        .gradient-line {
            flex: 1;
            height: 2px;
            background: linear-gradient(to right, #ffffff, #171717);
        }
    }
    .inv-dash-section {
        .description {
            border: 2px solid black;
            background-color: #181c1e;
            padding: 1.7rem 1rem 2rem 1rem;
            border-radius: 4px;
            margin-right: 2rem;
            margin-left: 1.5em;
        }
        .description-list > li {
            margin: 0;
            padding: 0.75rem 0px 0.75rem 3rem;
            list-style: none;
            background-image: url("./images/li-arrow.png");
            background-repeat: no-repeat;
            background-position: left center;
            background-size: 1.7rem;
            color: lightgray;
            font-size: 1.2rem;
        }
        .gradient-line {
            flex: 1;
            height: 2px;
            background: linear-gradient(to right, #ffffff, #171717);
        }
    }
    .comparison-section {
        .table-container {
            width: 100%;
            // overflow-x: hidden;
        }
        .table {
            
            color: lightgray;
            width: 100%;
            tr:hover {
                color: lightgray;
            }
            // border: 1px solid red;
            td, th {
                border: 1px solid #1d2124;
            }
            th {
                background-color: #131313;
                border: 1px solid #131313;
                // color: #171717;
            }
            th:nth-child(1) {
                border: none;
                // background-color: transparent;
            }
            
            tr:nth-child(7) > td:nth-child(2) {
                border-bottom: 1px solid #27debf;
            }
            
            td:nth-child(1) {
                background-color: #131313;
                // border: 1px solid #131313;
                border: none;
                border-right: 1px solid #27debf;
            }
            td:nth-child(2) {
                // background-color: red;
                border: 1px solid #27debf;
                border-bottom: none;
                border-top: none;
                // zoom: 1.03;
            }
            th:nth-child(2) {
                background-color: #27debf;
                border: 1px solid #27debf;
                color: #171717;
            }
            th {
                text-align: center;
            }
            td {
                text-align: center;
            }
            .wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .icon {
                height: 26px;
                width: 26px;
                box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                margin: -3.5px;
                transition: transform 0.2s;
            }
            .icon:hover {
                z-index: 10;
                transform: scale(1.1) rotate(0deg);
            }
            .more-count {
                background: lightgray;
                color: #171717;
                p {
                    font-size: 0.7rem;
                    font-weight: 600;
                }
            }
        }
        
    }
    .trusted-section {
        .trust-logo-container-desktop  {
            width: 100%;
        }
        .trust-logo-container-mobile {
            display: none;
        }
    }

    @media (max-width: 576px) {
        .title-right-grill {
            background-size: 70%;
            background-position-x: 150%;
        }

        .title-left-grill {
            background-size: 70%;
            background-position-x: -50%;
        }

        .section-margin {
            margin-bottom: 3rem;
        }
        .hero-content {
            .hero-title {
                margin-top: 0rem;
                padding-left: 6%;
                .sub-title-typing {
                    font-size: 2.25rem;
                }
            }
            .hero-img {
                max-height: 65vh;
                margin-top: 3rem;
                // margin-right: -15px;
            }
            .display-3 {
                font-size: 2.7rem;
            }
            .sub-title {
                font-size: 1.25rem;
            }
            .audited-by-container {
                flex-direction: column;
                align-items: start;
                .audit-by-title {
                    margin-bottom: 0.3rem !important;
                }
                .certik-audit-logo {
                    width: 7rem;
                }
                .immune-logo {
                    margin-top: 0.75rem;
                    margin-left: 0 !important;
                }
                img {
                    width: 7.4rem;
                }
            }
        }
        .media-section {
            .title-container {
                // background-color: green;
                margin-right: 0rem;
                margin-bottom: 0.5rem;
            }

            .media-title {
                margin-top: 0.5rem;
            }

            .content-padding {
                padding: 0% 4%;
            }

            .chain-logo-container {
                margin: 0.3rem 1rem 0.5rem 1rem;
            }

            .media-section-flex {
                flex-direction: column;
            }

            .mobile-small {
                max-width: 55%;
            }
            .desktop-large {
                max-width: 60%;
            }
        }

        .section-sub-title {
            font-size: 0rem;
            display: none;
        }

        .type-of-vault-section {
            .content-padding {
                padding: 0 1% 0 1%;
            }

            .vault-card {
                width: unset;
                height: unset;
                margin-bottom: 2rem;
            }
        }

        .offering-section {
            .item {
                margin-bottom: 2rem;
            }
            .offering-card-container-1 {
                padding: 0;
            }
            .offering-card-container-2 {
                padding: 0;
            }
            .offering-card {
                padding: 1.8rem;
            }
            .propositions-list > li {
                font-size: 0.95rem;
            }
        }
        .token-vesting-section {
            .description {
                padding: 1rem 0.8rem;
                margin: 2rem 0rem 0rem 0rem;
            }
            .description-list {
                padding-left: 1rem;
            }
            .description-list > li {
                margin: 0;
                padding: 0.3rem 0px 0.3rem 2.8rem;
                background-size: 1.2rem;
                font-size: 0.95rem;
            }
        }
        .lp-lock-section {
            .description {
                padding: 1rem 0.8rem;
                margin: 2rem 0rem 0rem 0rem;
            }
            .description-list {
                padding-left: 1rem;
            }
            .description-list > li {
                margin: 0;
                padding: 0.3rem 0px 0.3rem 2.8rem;
                background-size: 1.2rem;
                font-size: 0.95rem;
            }
            .flex-sm-column-reverse-custom {
                flex-direction: column-reverse;
            }
        }
        .inv-dash-section {
            .description {
                padding: 1rem 0.8rem;
                margin: 2rem 0rem 0rem 0rem;
            }
            .description-list {
                padding-left: 1rem;
            }
            .description-list > li {
                margin: 0;
                padding: 0.3rem 0px 0.3rem 2.8rem;
                background-size: 1.2rem;
                font-size: 0.95rem;
            }
        }
        .comparison-section {
            .table-container {
                width: 100%;
                overflow-x: scroll;
            }
        }
        .trusted-section {
            .trust-logo-container-desktop  {
                display: none;
            }
            .trust-logo-container-mobile {
                display: flex;
            }
        }
    }
}
