.data-card {
    background: #171717 0% 0% no-repeat padding-box;
    border-radius: 10px;
}

.data-icon {
    margin: 1.68rem 1rem 1.68rem 2.31rem;
    width: 5.6rem;
    height: 5.6rem;
}

.data-card-title {
    font: 22px/26px;
    letter-spacing: 0.44px;
    font-weight: 600;
    color: lightgray;
    opacity: 1;
}

.data-card-caption {
    letter-spacing: 0.28px;
    color: #9a9a9b;
    opacity: 1;
}

@media (min-width: 1025px) and (max-width: 1280px) {
    .data-icon {
        margin: 1.68rem 1rem 1.68rem 1rem;
    }
}

@media (max-width: 480px) {
    .data-icon {
        margin: 1.68rem 1rem 1.68rem 1rem;
        width: 60px;
        height: 60px;
    }
}