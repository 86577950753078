.page-heading {
    padding-top: 7rem;
    margin-bottom: 3rem;
}

.page-title-left-grill {
    background-image: url(./images/left-grill.svg);
    background-repeat: no-repeat;
    background-size: 27%;
    background-position-x: 0%;
    background-position-y: 5rem;
}

.page-title-right-grill {
    background-image: url(./images/right-grill.svg);
    background-repeat: no-repeat;
    background-size: 28%;
    background-position-x: 100%;
    background-position-y: 100%;
}

.page-title {
    text-align: center;
    font-size: 2.8rem;
    font-weight: 800;
    // font: normal normal bold;
    letter-spacing: 1px;
    color: lightgray;
    opacity: 1;
    padding-top: 1.5rem;
}

.page-sub-title {
    color: lightgray;
    opacity: 0.05;
    font-size: 4.5rem;
    position: absolute;
    left: 50%;
    text-transform: uppercase;
    white-space: nowrap;
    transform: translate(-50%, 0%);
    font-weight: 900;
    letter-spacing: 0.1rem;
}

@media (min-width: 1025px) and (max-width: 1280px) {
    .page-heading {
        padding-top: 10rem;
    }

    .page-title-right-grill {
        background-size: 35%;
        background-position-y: 80%;
    }

    .page-title-left-grill {
        background-size: 60%;
        background-position-y: 60%;
    }

    .page-sub-title {
        font-size: 5.5rem;
        top: 20%;
    }
}

/* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) {
    .page-heading {
        padding-top: 7rem;
        margin-bottom: 3rem;
    }

    .page-title-left-grill {
        background-size: 60%;
        background-position-y: 70%;
    }

    .page-title-right-grill {
        background-size: 35%;
        background-position-y: 80%;
    }

    .page-sub-title {
        font-size: 5.5rem;
        top: 7rem;
    }
}

/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

@media (min-width: 481px) and (max-width: 767px) {
    .page-heading {
        padding-top: 6rem;
        margin-bottom: 2rem;
    }

    .page-title-left-grill {
        background-size: 80%;
        background-position-y: 70%;
    }

    .page-title-right-grill {
        background-size: 35%;
        background-position-y: 80%;
    }

    .page-sub-title {
        font-size: 3.5rem;
        top: 7rem;
    }
}

/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

@media (max-width: 480px) {
    .page-heading {
        padding-top: 5.5rem;
        margin-bottom: 3rem;
    }

    .page-title {
        font-size: 2rem;
    }
    
    .page-sub-title {
        display: none;
    }

    .page-title-right-grill {
        background: none;
    }

    .page-title-left-grill {
        background-size: 80%;
        background-position-y: 70%;
    }

    .page-sub-title {
        font-size: 2.5rem;
        top: 19%;
    }
}
